import { FC } from 'react'
import {
    Container,
    Heading,
    Stack,
    Image,
    VStack,
    Text,
    useColorModeValue,
    Flex,
    Center,
} from '@chakra-ui/react'
import { Element } from 'react-scroll'
import Fade from 'react-reveal/Fade'
import headshot from '../../media/headshot.jpg'

const About: FC = () => {
    return (
        <Element name='aboutme'>
            <Container maxW={'5xl'} p={6.75} mt={[20, 10]}>
                <VStack spacing={8}>
                    <Fade>
                        <Heading as='h2'>About me</Heading>
                    </Fade>
                    <Stack
                        position='relative'
                        alignItems='center'
                        direction={['column', 'row']}
                        textAlign={{ base: 'left', sm: 'justify', lg: 'left' }}
                    >
                        <Image
                            borderRadius='full'
                            boxSize='150px'
                            src={headshot}
                            border={`3px solid ${useColorModeValue('#1E2428', '#ECEFF4')}`}
                        />
                        <VStack spacing={4} p={[10, 4]} pl={[10, 50]}>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>👋🏼</Center>
                                    <Text>
                                        Hi, I'm Max and I'm a fourth year
                                        student at the{' '}
                                        <b>University of California, San Diego</b>. I am an incoming 
                                        Software Engineer specializing in <b>back-end development and infrastructure</b>,
                                        crafting robust and scalable solutions that power exceptional software products.
                                    </Text>
                                </Flex>
                            </Fade>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>🎓</Center>
                                    <Text>
                                        I am currently pursuing a Bachelor's of Science in{' '}
                                        <b>Computer Science</b> with a GPA of <b>3.92/4</b>,
                                        graduating class of 2025.
                                    </Text>
                                </Flex>
                            </Fade>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>💼</Center>
                                    <Text>
                                        I am currently doing <b>Software Engineering at Occuspace</b>, as I have been for much of the past two years 
                                        - with a primary focus of optimizing backend performance and devloping APIs.
                                        I previously held a position as a{' '}
                                        <b>Data Analytics Co-Op at the Toronto Star</b>{' '}
                                        working as part of both the Data Engineering and Data Science teams. 
                                    </Text>
                                </Flex>
                            </Fade>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>👨🏻‍💻</Center>
                                    <Text>
                                        I'm experienced in full-stack web
                                        development {'('}but focus primarily in backend{')'}- particularly with{' '}
                                        <b>Typescript, Go,</b> and <b>Java</b>.{' '}
                                        <b>ReactJS</b> is my frontend framework
                                        of choice and I've also done significant work in backend
                                        with{' '}
                                        <b>NodeJS, C, C++,</b> and <b>Python</b>.
                                    </Text>
                                </Flex>
                            </Fade>
                            
                        </VStack>
                    </Stack>
                </VStack>
            </Container>
        </Element>
    )
}

export default About
