import { Box, useColorModeValue, keyframes } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

const Background = () => {
    const [isScrolling, setIsScrolling] = useState(false);
    const color = useColorModeValue('#dadada', '#444');

    const dotPattern = encodeURIComponent(`
        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="100%" fill="none"/>
            
            <!-- First row of dots -->
            <circle cx="5" cy="5" r="2" fill="${color}" />
            <circle cx="20" cy="5" r="2" fill="${color}" />
            
            <!-- Offset second row -->
            <circle cx="12.5" cy="15" r="2" fill="${color}" />
            <circle cx="27.5" cy="15" r="2" fill="${color}" />
        </svg>
    `);

    const gradient = useColorModeValue(
        'linear-gradient(160deg, rgba(238,242,246,1) 0%, rgba(242,242,242,0.8) 38%, rgba(242,242,242,0.8) 64%, rgba(191, 214, 222, 0.85) 100%), rgb(238,242,246)',
        'linear-gradient(160deg, rgba(23,25,34,1) 0%, rgba(26,32,43,1) 30%, rgb(35, 43, 58) 70%, rgba(58, 81, 90, 0.75) 100%), rgb(23,25,34)'
    );

    const shiftRight = keyframes`
        0% { background-position: 0 0; }
        100% { background-position: 100px 0; }
    `;

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolling(true);
            window.removeEventListener('scroll', handleScroll); // Remove the event listener after first scroll
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
        };
    }, []);

    return (
        <Box
            position="fixed"
            h="100vh"
            w="100vw"
            zIndex={-100}
            top={0}
            left={0}
            background={`url("data:image/svg+xml,${dotPattern}"), ${gradient}`}
            backgroundSize="30px 30px, cover"
            backgroundRepeat="repeat, no-repeat"
            animation={isScrolling ? 'none' : `${shiftRight} 2s linear infinite`}
            color="white"
        />
    );
};

export default Background;
