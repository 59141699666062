import { ExperienceData } from './types'
import occuspaceLogo from '../media/occuspace_logo.png'
import occuspaceOldLogo from '../media/occuspaceold_logo.jpg'
import centroLogo from '../media/centro_logo.png'
import torontoStarLogo from '../media/torontostar_logo.png'
import worldAffairsConLogo from '../media/worldaffairsconference_logo.jpg'

export const experienceData: ExperienceData[] = [
    {
        title: 'Machine Learning Software Engineer Intern',
        company: 'Occuspace',
        location: 'San Diego, CA',
        dates: 'March 2024 - September 2024',
        description:
            'Developed customer-facing APIs and internal portal APIs, both of which are used regularly in production. Revamped metric pipeline to filter out inadequate data packets.',
        logoSrc: occuspaceLogo
    },
    {
        title: 'Software Engineer Intern',
        company: 'Occuspace',
        location: 'San Diego, CA',
        dates: 'March 2023 - June 2023',
        description:
            'Optimized backend performance and cut costs by minimizing database hits. Migrated key analytics algorithms from JavaScript to Go, improving efficiency and scalability.',
        logoSrc: occuspaceOldLogo
    },
    {
        title: 'Co-Founder, Chief of Technology',
        company: 'Centro Cleaning',
        location: 'Toronto, ON',
        dates: 'May 2022 - September 2023',
        description:
            'Spearheaded the development of a full-stack web and mobile application, founded and built from scratch a mobile car detailing business that serviced 200+ unique clients with a team of 10+ employees.',
        logoSrc: centroLogo
    },
    {
        title: 'Data Analytics Co-op',
        company: 'Toronto Star',
        location: 'Toronto, ON',
        dates: 'May 2021 - August 2021',
        description:
            'Quantitatively improved data warehousing efficiency, monitored daily ETL processes - even independently created an ETL procedure to transform raw social media metrics into comprehensive, daily graphs used by the CDO to inform decisions. Identified and mended flaws in the Gift Subscription service that reduced gift redemption by 13%.',
        logoSrc: torontoStarLogo,
    },
    {
        title: 'Software Developer, Head of Technology',
        company: 'World Affairs Conference',
        location: 'Toronto, ON',
        dates: 'September 2020 - March 2021',
        description:
            'Pioneered the development of the conference\'s website using modern web dev practices. Virtually ran the largest student-led conference in Canada.',
        logoSrc: worldAffairsConLogo,
    },
]
