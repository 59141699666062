import {
    FirebaseBadge,
    JavaBadge,
    NodeBadge,
    PythonBadge,
    ReactBadge,
    PhaserBadge,
    TypescriptBadge,
    PostgresBadge,
    JavascriptBadge,
    HTMLBadge,
    CSharpBadge,
    UnityBadge,
    SvelteBadge,
} from '../components/Projects/TechBadges'
import { FiGithub, FiLink } from 'react-icons/fi'
import { ProjectT } from './types'

export const projectData: ProjectT[] = [
    {
        name: 'Rememory',
        id: 'rememory',
        type: 'Web app',
        tagline: 'A social media app to immortalize yourself.',
        tags: [<ReactBadge />, <FirebaseBadge />, <NodeBadge />],
        imageSrc: '/rememory.png',
        description:
            'A social media web application that utilizes Artificial Intelligence to "immortalize yourself". Record yourself speaking about your life and experiences, and others can ask your profile questions that will be responded to by a clone of yourself. No GitHub link in order to protect proprietary information, for a code tour please reach out to me.',
    },
    {
        name: 'Max Rivett - The Game!',
        id: 'the-game',
        type: 'Web game',
        tagline: 'Learn about me in a (hopefully) fun way!',
        tags: [
            <TypescriptBadge />,
            <PhaserBadge />,
        ],
        imageSrc: '/newgame.png',
        description:
            'A top-down RPG walking you through my life, built using TypeScript and Phaser3 web game framework. Collaborated with the CTO of Phaser to make specific improvements and bug fixes to the framework, individually created tens of tilemaps and many characters/dialogues.',
        links: [
            {
                link: 'https://github.com/maxrivett/personal-site-game2/',
                icon: <FiGithub />,
            },
            {
                link: 'https://games.maxrivett.com/newgame/index.html',
                icon: <FiLink />,
            },
        ],
        headerLink: 'https://games.maxrivett.com/newgame/index.html',
    },
    {
        name: 'TritonLink Replica',
        id: 'tritonlink',
        type: 'Website',
        tagline: 'Top-notch practices for a complex relational database.',
        tags: [<PostgresBadge />, <JavaBadge />],
        imageSrc: '/relational.png',
        description:
            'Built on open-source Postgres DBMS with Java and JDBC drivers, this project replicates TritonLink, UCSD\'s online hub. The web interface is built with JSPs and runs on Apache Tomcat. Uses many foundational principles of SQL, including materialized views, constraints, reports, decision support queries, and more - all with extensive data maintenance and querying requirements to ensure integrity.',
        links: [
            {
                link: 'https://github.com/maxrivett/TritonLink-Replica',
                icon: <FiGithub />,
            },
        ],
        headerLink: 'https://github.com/maxrivett/TritonLink-Replica',
    },
    {
        name: 'World Affairs Conference',
        id: 'wac',
        type: 'Website',
        tagline: 'Website for the largest student-run conference in Canada.',
        tags: [<SvelteBadge />, <TypescriptBadge />],
        imageSrc: '/wac.png',
        description:
            "I led the development of the World Affairs Conference's official website, employing modern web technologies to create a dynamic and user-friendly platform. The site features responsive design, seamless navigation, and integrates tools for online registration and event management, enhancing the overall experience for attendees and organizers alike.",
        links: [
            {
                link: 'https://github.com/worldaffairsconference/worldaffairscon.org',
                icon: <FiGithub />,
            },
            {
                link: 'https://worldaffairscon.org/',
                icon: <FiLink />,
            },
        ],
    },
    {
        name: 'Max Rivett - The (Old) Game!',
        id: 'the-old-game',
        type: 'Web game',
        tagline:
            'Learn about me in a (hopefully) fun way!',
        tags: [<HTMLBadge />, <JavascriptBadge />],
        imageSrc: '/oldgame.png',
        description:
            'A custom-built 2D platformer game that walks you through my life (at the time I built it at), built entirely vanilla JS using the HTML5 canvas. Showcases smooth animations and dynamic level rendering from JSON files.',
        links: [
            {
                link: 'https://games.maxrivett.com/?game',
                icon: <FiLink />,
            },
        ],
        headerLink: 'https://games.maxrivett.com/?game',
    },
    {
        name: 'Fortune Teller',
        id: 'fortune-teller',
        type: 'Website',
        tagline:
            'Get your fortune told!',
        tags: [<JavascriptBadge />],
        imageSrc: '/fortunecookies.png',
        description:
            'A responsive web application leveraging modern JS and CSS to deliver an interactive tarot card reading experience (with unique fortunes from the GPT API). Features dynamic DOM manipulation, animations, and audio integration to enhance user engagement. Follows best practices in software development, including modular code design, comprehensive documentation, and robust testing frameworks for maintainability.',
        links: [
            {
                link: 'https://github.com/cse110-sp23-group12/cse110-sp23-group12',
                icon: <FiGithub />,
            },
            {
                link: 'https://cse110-sp23-group12.github.io/cse110-sp23-group12/',
                icon: <FiLink />,
            },
        ],
        headerLink: 'https://cse110-sp23-group12.github.io/cse110-sp23-group12/',
    },
    {
        name: 'Slack Memebot',
        id: 'slack-memebot',
        type: 'Bot / App Plugin',
        tagline: 'A bot that sends AI-generated user-prompted memes.',
        tags: [<PythonBadge />],
        imageSrc: '/slackbot.png',
        description:
            'A Slack bot that generates memes using GPT-3.5, allowing users to create memes directly within Slack channels. The bot is implemented in Python and integrates with the Slack API to handle slash commands, providing an interactive, entertaining experience.',
        links: [
            {
                link: 'https://github.com/maxrivett/slack_memebot',
                icon: <FiGithub />,
            },
        ],
        headerLink: 'https://github.com/maxrivett/slack_memebot',
    },
    {
        name: 'Pokemon Battle Game',
        id: 'pokemon',
        type: 'Game app',
        tagline: 'Turn-based battle game with Pokemon characters.',
        tags: [<CSharpBadge />, <UnityBadge />],
        imageSrc: '/pokebattle.png',
        description:
            "A polished turn-based battle game in Unity; includes custom animations, dynamic effects, and a thoughtfully crafted combat system, showcasing a blend of creativity and technical expertise in game development.",
        links: [
            {
                link: 'https://github.com/maxrivett/BlastoisePokeBattle',
                icon: <FiGithub />,
            },
        ],
    },
    {
        name: 'Website for the School Weightlifting Gym',
        id: 'sas',
        type: 'Website',
        tagline: 'Website to track records at my high school\'s weightlifting gym.',
        tags: [<HTMLBadge />, <JavascriptBadge />],
        imageSrc: '/sas.png',
        description:
            "A personal best tracking website for my high school's weightlifting gym (the SAS), utilizing HTML, CSS, and JavaScript to create an intuitive and responsive user interface. The platform enables students to log and monitor their performance metrics across various activities, fostering a culture of self-improvement and goal-setting within the school community.",
        links: [
            {
                link: 'https://github.com/maxrivett/CS_IA',
                icon: <FiGithub />,
            },
        ],
    },
]
