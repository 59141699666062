import { Badge } from '@chakra-ui/react'

export const ReactBadge = () => {
    return <Badge colorScheme={'teal'}>ReactJS</Badge>
}

export const PythonBadge = () => {
    return <Badge colorScheme={'blue'}>Python</Badge>
}

export const PHPBadge = () => {
    return <Badge colorScheme={'purple'}>PHP</Badge>
}

export const JavaBadge = () => {
    return <Badge colorScheme={'red'}>Java</Badge>
}

export const MySQLBadge = () => {
    return <Badge colorScheme={'orange'}>MySQL</Badge>
}

export const MongoBadge = () => {
    return <Badge colorScheme={'green'}>MongoDB</Badge>
}

export const NodeBadge = () => {
    return <Badge colorScheme={'yellow'}>NodeJS</Badge>
}

export const FirebaseBadge = () => {
    return <Badge colorScheme={'orange'}>Firebase</Badge>
}

export const AssemblyBadge = () => {
    return <Badge colorScheme={'orange'}>Assembly</Badge>
}

export const DjangoBadge = () => {
    return <Badge colorScheme={'green'}>Django</Badge>
}

export const TypescriptBadge = () => {
    return <Badge colorScheme={'blue'}>TypeScript</Badge>
}

export const PhaserBadge = () => {
    return <Badge colorScheme={'orange'}>Phaser</Badge>
}

export const PostgresBadge = () => {
    return <Badge colorScheme={'green'}>Postgres</Badge>
}

export const JavascriptBadge = () => {
    return <Badge colorScheme={'purple'}>JavaScript</Badge>
}

export const HTMLBadge = () => {
    return <Badge colorScheme={'pink'}>HTML</Badge>
}

export const CSharpBadge = () => {
    return <Badge colorScheme={'pink'}>C#</Badge>
}

export const UnityBadge = () => {
    return <Badge colorScheme={'orange'}>Unity</Badge>
}

export const SvelteBadge = () => {
    return <Badge colorScheme={'orange'}>Svelte</Badge>
}