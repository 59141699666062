import { FC } from 'react'
import {
    Container,
    VStack,
    Stack,
    Heading,
    StackDivider,
    useColorModeValue,
} from '@chakra-ui/react'
import {
    SiJava,
    SiPython,
    SiReact,
    SiTypescript,
    SiBootstrap,
    SiMysql,
    SiGit,
    SiDocker,
    SiGo,
    SiPostgresql,
    SiFirebase,
    SiAmazonaws,
    SiCplusplus,
    SiTensorflow,
    SiSnowflake,
    SiJupyter,
    SiLabview,
    SiUnity,
    SiYoutubegaming,
    SiDatabricks,
} from 'react-icons/si'
import { Element } from 'react-scroll'
import Fade from 'react-reveal/Fade'
import SkillSection from './SkillSection'
import SkillItem from './SkillItem'
import Card from '../generics/Card'

const Skills: FC = () => {
    return (
        <Element name='skills'>
            <Container maxW={'5xl'} p={6.5} mt={20}>
                <VStack spacing={8}>
                    <Fade>
                        <Heading as='h2'>Skills</Heading>
                    </Fade>
                    <Stack direction={'row'}>
                        <Card>
                            <Stack
                                direction={['column', 'row']}
                                divider={
                                    <StackDivider
                                        borderColor={useColorModeValue(
                                            'gray.300',
                                            'gray.600'
                                        )}
                                    />
                                }
                                spacing={8}
                                align={'flex-start'}
                            >
                                {/* Updated to only have top 5 each */}
                                <SkillSection title={'Languages'}>
                                    <SkillItem icon={SiPython} name='Python' />
                                    <SkillItem icon={SiTypescript}name='TypeScript'/>
                                    {/* <SkillItem icon={SiJavascript}name='JavaScript'/> */}
                                    <SkillItem icon={SiJava} name='Java' />
                                    {/* <SkillItem icon={SiHtml5} name='HTML/CSS' /> */}
                                    <SkillItem icon={SiGo} name='Go' />
                                    {/* <SkillItem icon={SiC} name='C' /> */}
                                    {/* <SkillItem icon={SiCsharp} name='C#' /> */}
                                    <SkillItem icon={SiCplusplus} name='C++' />
                                    {/* <SkillItem icon={SiPowershell} name='Shell' /> */}
                                    {/* <SkillItem icon={SiV} name='SystemVerilog' /> */}
                                </SkillSection>
                                <SkillSection title={'Frameworks'}>
                                    <SkillItem icon={SiReact} name='ReactJS' />
                                    {/* <SkillItem icon={SiNodedotjs}  name='NodeJS' /> */}
                                    {/* <SkillItem icon={SiFlask} name='Flask' /> */}
                                    {/* <SkillItem icon={SiDjango} name='Django' /> */}
                                    <SkillItem  icon={SiBootstrap} name='Bootstrap' />
                                    {/* <SkillItem  icon={SiMaterialui} name='Materialize' /> */}
                                    <SkillItem  icon={SiTensorflow} name='TensorFlow' />
                                    {/* <SkillItem  icon={SiUnrealengine} name='Unreal Engine' /> */}
                                    {/* <SkillItem  icon={SiGodotengine} name='Godot' /> */}
                                    <SkillItem  icon={SiUnity} name='Unity' />
                                    <SkillItem  icon={SiYoutubegaming} name='Phaser' />
                                </SkillSection>
                            </Stack>
                        </Card>
                        <Card>
                            <Stack
                                direction={['column', 'row']}
                                divider={
                                    <StackDivider
                                        borderColor={useColorModeValue(
                                            'gray.300',
                                            'gray.600'
                                        )}
                                    />
                                }
                                spacing={8}
                                align={'flex-start'}
                            >
                                <SkillSection title={'Databases'}>
                                    <SkillItem icon={SiMysql} name='MySQL' />
                                    <SkillItem icon={SiPostgresql} name='PostgreSQL' />
                                    {/* <SkillItem icon={SiMongodb} name='MongoDB' /> */}
                                    <SkillItem icon={SiFirebase} name='Firestore' />
                                    {/* <SkillItem icon={SiSqlite} name='SQLite' /> */}
                                    <SkillItem icon={SiSnowflake} name='Snowflake' />
                                    <SkillItem icon={SiDatabricks} name='Memcached' />
                                </SkillSection>
                                <SkillSection title={'Other tools'}>
                                    <SkillItem icon={SiGit} name='Git' />
                                    {/* <SkillItem icon={SiLatex} name='LaTeX' /> */}
                                    <SkillItem icon={SiDocker} name='Docker' />
                                    {/* <SkillItem icon={SiVisualstudiocode} name='VS Code'/> */}
                                    <SkillItem icon={SiAmazonaws} name='AWS' />
                                    {/* <SkillItem icon={SiPostman} name='Postman' /> */}
                                    {/* <SkillItem icon={SiTerraform} name='Terraform' /> */}
                                    <SkillItem icon={SiJupyter} name='Jupyter Notebook' />
                                    <SkillItem icon={SiLabview} name='LabView' />
                                    {/* <SkillItem icon={SiBlender} name='Blender' /> */}
                                </SkillSection>
                            </Stack>
                        </Card>
                    </Stack>
                </VStack>
            </Container>
        </Element>
    )
}

export default Skills
